<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card style="border-radius: 20px">
          <v-overlay absolute style="opacity: 0.6" :value="isLoadingProposal">
            <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
          </v-overlay>
          <v-data-table :headers="headers" :items="filterItems" :custom-sort="customSort"
            no-data-text="Sem Propostas para exibir" style="border-radius: 20px">
            <template v-slot:top>
              <div class="title-displays" style="padding-top: 15px; padding-left: 15px">
                Propostas
              </div>
              <v-spacer></v-spacer>

              <!-- Busca -->
              <v-row style="justify-content: space-between">
                <v-text-field style="width: 80%; padding: 1em" class="px-4" prepend-icon="search" label="Busca"
                  v-model="searchText"></v-text-field>
                <v-select hint="Buscar nessa coluna" persistent-hint style="width:20%; padding: 1em" item-text="text"
                  item-value="value" v-model="columnToSeach" :items="columnsToChoose" hide-details
                  single-line></v-select>
              </v-row>
            </template>
            <template v-slot:item.type="{ item }">
              {{ checkProposalTypes(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn elevation="2" v-bind="attrs" v-on="on" :loading="idProposalPDF == item.id"
                    :disabled="idProposalPDF != '' && idProposalPDF != item.id">
                    <v-icon color="primary" small>
                      mdi-text-box-multiple-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(menuIten, index) in itemsMenu" :key="index" link
                    @click="menuItensSelect(item, menuIten.value)">
                    <v-list-item-title>{{
                      menuIten.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn elevation="2" v-bind="attrs" v-on="on" :loading="idProposalPDF == item.id"
                    :disabled="checkProposalPrices(item) || idProposalPDF != '' && idProposalPDF != item.id">
                    <v-icon color="primary" small>
                      mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(menuIten, index) in filterButtonMenuItens(item)" :key="index" link
                    @click="pdfRequest(item, menuIten.value)">
                    <v-list-item-title>{{
                      menuIten.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <PerformanceROI :modal="PerformanceROIModal" @close="() => closePerformanceROIModal()" :proposalType="'waterscan'"
      :idProposal="idProposal" />
    <SalesROI :modal="SalesROIModal" @close="() => closeSalesROIModal()" :proposalType="'waterscan'"
      :idProposal="idProposal" />
    <FinancialFlowROI :modal="FinancialFlowROIModal" :idProposal="idProposal" :proposalType="'waterscan'"
      :clientName="clientName" />
    <!-- Inicio Nova Proposta -->
    <NewWaterScan v-if="openViewProposal" :open="openViewProposal" :closeFunc="closeComponent"
      :registration="registration" :idProposal="idProposal" :viewOrEdit="'view'" />
    <NewProposalSimplified v-if="openViewSimplifiedProposal" :openNewProposalSimplified="openViewSimplifiedProposal"
      :closeFunc="closeComponent" :registration="registration" :idProposal="idProposal" :viewOrEdit="'view'" />
    <!-- Fim Nova Proposta -->
  </v-container>
</template>
<script>
import WaterScanScript from "./WaterScanScript";
export default WaterScanScript;
</script>