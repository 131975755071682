import firebase from "@/firebase";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import NewWaterScan from "../NewProposal/NewWaterScan.vue"
import NewProposalSimplified from "../NewProposalSimplified/NewProposalSimplified.vue"
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper"
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import WaterScanApi from "@/services/apiPdf/WaterScanApi";
import Utils from '@/scripts/Utils';
import PerformanceROI from '@/components/ROI/Performance/PerformanceROI.vue'
import SalesROI from '@/components/ROI/Sales/SalesROI.vue'
import FinancialFlowROI from '@/components/ROI/FinancialFlow/FinancialFlowROI.vue'
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import BucketService from '@/services/server/BucketService';


export default {
  name: "ConsultWaterScan",
  components: {
    NewWaterScan,
    NewProposalSimplified,
    PerformanceROI,
    SalesROI,
    FinancialFlowROI,
  },
  data: function () {
    return {
      itemsMenuPDF: [
        { value: 'proposed', title: 'Proposta' },
        { value: 'checklist', title: 'Checklist Simples' },
        { value: 'checklist_with_photo', title: 'Checklist Foto' },
        { value: 'performance-roi', title: 'ROI de Performance' },
        { value: 'sales-roi', title: 'ROI de Venda' },
        { value: 'financial-flow', title: 'Fluxo Financeiro' },
      ],
      itemsMenu: [
        { value: 'edit', title: 'Editar' },
        { value: 'copy', title: 'Copiar' },
        { value: 'delet', title: 'Excluir' },
      ],
      idProposalPDF: "",
      registration: "",
      idProposal: "",
      openViewProposal: false,
      openViewSimplifiedProposal: false,
      isLoadingProposal: false,
      proposalDelete: null,
      register: this.$route.params.register,
      proposalList: [],
      headers: [
        { text: "Cliente", value: "locationInformation.name" },
        { text: "Tipo", value: "type", width: 200 },
        { text: "Ações", value: "actions", sortable: false, width: 180 },
      ],
      columnToSeach: "client",
      searchText: "",
      columnsToChoose: [
        { text: "Cliente", value: "client" },
        { text: "Tipo", value: "type" }
      ],
      clientName: '',
      PerformanceROIModal: false,
      SalesROIModal: false,
      FinancialFlowROIModal: false,
      proposalROI: null,
    }
  },
  methods: {
    ...mapActions([
      'closeChannel',
      'subscribeChannel',
    ]),
    customSort(items, sortBy, sortDesc) {
      if (sortBy[0] === 'type') {
        return items.sort((a, b) => {
          const nameA = this.checkProposalTypes(a).toLowerCase();
          const nameB = this.checkProposalTypes(b).toLowerCase();
          if (nameA < nameB) return sortDesc[0] ? 1 : -1;
          if (nameA > nameB) return sortDesc[0] ? -1 : 1;
          return 0;
        });
      }
      else if (sortBy[0] === 'locationInformation.name') {
        return items.sort((a, b) => {
          const nameA = a.locationInformation.name.toLowerCase();
          const nameB = b.locationInformation.name.toLowerCase();
          if (nameA < nameB) return sortDesc[0] ? 1 : -1;
          if (nameA > nameB) return sortDesc[0] ? -1 : 1;
          return 0;
        });
      }
      return items;
    },
    checkProposalTypes(proposal) {
      if (proposal.proposed?.typeProposal?.type) {
        switch (proposal.proposed.typeProposal.type) {
          case "model_performance":
            return "Modelo Performance"
          case "payback_sell":
            return "Venda do Projeto"
          case "investment":
            return "Investimento"
          case "pre_fixed":
            return "Pré-Fixado"
          case "club_foz":
            return "Pré-Clube Foz"
          case "simplified":
            return "Simplificada"
          default:
            return "Não identificado!"
        }
      } else return "Não definido!"
    },
    menuItensSelect(proposal, menuItem) {
      switch (menuItem) {
        case "edit":

          this.openViewComponent(proposal.id)
          break;
        case "copy":
          this.copyProposal(proposal)
          break;
        case "delet":
          this.getProposalDelet(proposal)
          break
        default:
          return "Não identificado!"
      }
    },
    async copyProposal(proposal) {
      this.isLoadingProposal = true
      try {
        let saveProposal = proposal
        const idDocRef = await WaterScanService.generateIdDocRef()
        const destPath = "water_scan_imgs/" + idDocRef

        if (proposal.solutionList && proposal.solutionList.length) {
          const solution = proposal.solutionList.find(solution => solution.imgNameList.length ? true : false)
          if (solution && solution.imgNameList) {
            const text = solution.imgNameList[0]
            const regex = /(?<=\/)[^\/]+(?=\/)/;
            const match = text.match(regex);
            await BucketService.copyFolder("water_scan_imgs/" + match[0], destPath)
            let newSolutionList = [...proposal.solutionList]
            newSolutionList.forEach(solution => {
              if (solution.imgNameList && solution.imgNameList.length) {
                solution.imgNameList = solution.imgNameList.map(imagePath => imagePath.replace(/(?<=\/)[^\/]+(?=\/)/, idDocRef))
              }
            })
            saveProposal = { ...saveProposal, solutionList: newSolutionList }
          }
        }

        const copyProposalWithNewId = await WaterScanService.createProposal(saveProposal, idDocRef)
        this.proposalList.unshift(copyProposalWithNewId)
        showSuccessSnackBar("Proposta copiada com sucesso!")
      } catch (error) {
        console.error("copyProposal", error)
        showErrorSnackBar("Erro ao copiar proposta!")
      } finally { this.isLoadingProposal = false }
    },
    checkProposalPrices(proposal) {
      if (proposal.proposed?.typeProposal?.type === "simplified") return false
      if (!proposal.proposed || !proposal.solutionList) return true
      return false
    },
    async pdfRequest(proposal, action) {
      this.idProposalPDF = proposal.id
      try {
        let response = null
        switch (action) {
          case 'proposed':

            if (proposal.proposed?.typeProposal?.type === "simplified") response = await WaterScanApi.generateSimplifiedProposalPdf(proposal.id)
            else response = await WaterScanApi.generateProposalPdf(proposal.id)
            if (response.status === 200 && typeof response.data.fileName === "number") await WaterScanApi.downloadPdf(response.data.fileName, proposal.locationInformation.name)
            else showErrorSnackBar("Erro ao gerar proposta. Tente novamente!")
            this.statusRequest(response)
            break;
          case 'checklist':
            response = await WaterScanApi.generateChecklistPdf(proposal.id)
            if (response.status === 200 && typeof response.data.fileName === "number") await WaterScanApi.downloadPdf(response.data.fileName, proposal.locationInformation.name)
            else showErrorSnackBar("Erro ao gerar proposta. Tente novamente!")
            this.statusRequest(response)
            break;
          case 'checklist_with_photo':
            response = await WaterScanApi.generateChecklisWithPhototPdf(proposal.id)
            if (response.status === 200 && typeof response.data.fileName === "number") await WaterScanApi.downloadPdf(response.data.fileName, proposal.locationInformation.name)
            else showErrorSnackBar("Erro ao gerar proposta. Tente novamente!")
            this.statusRequest(response)
            break;
          case 'performance-roi':
            this.openPerformanceROIModal(proposal.id)
            break;
          case 'sales-roi':
            this.openSalesROIModal(proposal.id, proposal.locationInformation.name)
            break;
          case 'financial-flow':
            this.openFinancialFlowROIModal(proposal.id, proposal.locationInformation.name)
            break;
        }
      } catch (error) {
        console.error(error)
      }
      this.idProposalPDF = ""
    },
    filterButtonMenuItens(proposal) {
      if (proposal.proposed?.typeProposal?.type === "simplified") {
        return [{ value: 'proposed', title: 'Proposta' }]
      } else {
        return this.itemsMenuPDF
      }
    },
    async statusRequest(res) {
      if (res) {
        this.idProposalPDF = ""
      } else (
        this.idProposalPDF = "",
        alert("Erro ao gerar o PDF!")
      )
    },
    async openViewComponent(idProposal) {
      const response = await WaterScanService.getProposalById(idProposal)
      this.registration = response.registration
      this.idProposal = idProposal
      if (response.proposed?.typeProposal?.type === "simplified") this.openViewSimplifiedProposal = true
      else this.openViewProposal = true;

    },
    closeComponent() {
      this.getProposalList()
      this.openViewProposal = false;
      this.openViewSimplifiedProposal = false;
      let path = '/water-scan/consult-proposal/all'
      if (this.$route.path !== path) this.$router.push(path);
    },
    getProposalDelet(proposal) {
      this.proposalDelete = proposal
      showDeleteDialogAlert(`Deseja excluir a proposta do cliente "${proposal.locationInformation.name}"?`, this.deletProposal)
    },
    async deletProposal() {
      let proposal = this.proposalDelete
      try {
        this.isLoadingProposal = true
        if (proposal.solutionList && proposal.solutionList.length) {
          let listImagesDelete = []
          proposal.solutionList.forEach(solution => {
            if (solution.imgNameList && solution.imgNameList.length) listImagesDelete.push(...solution.imgNameList)
          })
          await BucketService.deletePathList(listImagesDelete)
        }
        await WaterScanService.deleteProposal(proposal.id)
        this.proposalList = this.proposalList.filter(({ id }) => id !== proposal.id)
        showSuccessSnackBar("Proposta copiada com sucesso!")
      } catch (error) {
        console.error("deleteProposal", error)
        showErrorSnackBar("Erro ao deletar proposta!")
      } finally { this.isLoadingProposal = false }
    },
    checkURL() {
      if (this.$route.params.idProposal == "all") {
        this.getProposalList()
      } else {
        this.openViewComponent(this.$route.params.idProposal)
        // this.getProposalList()
      }
    },
    async getProposalList() {
      this.isLoadingProposal = true
      await firebase.getProposal()
        .then(response => {
          this.proposalList = response
          this.isLoadingProposal = false
        })
        .catch(err => {
          alert("Erro ao consultar propostas!")
          this.isLoadingProposal = false
          console.error(err)
        })
    },
    openPerformanceROIModal(idProposal) {
      this.idProposal = idProposal
      this.PerformanceROIModal = true
    },
    closePerformanceROIModal() {
      this.PerformanceROIModal = false
      this.idProposal = null
    },
    openSalesROIModal(idProposal, clientName) {
      this.idProposal = idProposal
      this.clientName = clientName
      this.SalesROIModal = true
    },
    openFinancialFlowROIModal(idProposal, clientName) {
      this.idProposal = idProposal
      this.clientName = clientName
      this.FinancialFlowROIModal = true
    },
    closeSalesROIModal() {
      this.SalesROIModal = false
      this.idProposal = null
    },
  },
  computed: {
    filterItems() {
      let text = Utils.defaultSpace(this.searchText)
      let list = this.proposalList
      list = list.filter((proposed) => {
        let value = ""
        if (this.columnToSeach === "client") value = proposed.locationInformation.name
        if (this.columnToSeach === "type" && proposed.proposed?.typeProposal) value = proposed.proposed.typeProposal.type
        if (value) return (Utils.defaultSpace(value).toLowerCase().indexOf(text.toLowerCase()) > -1);
        else return ""
      });
      return list
    },
  },
  created() {
    this.checkURL();

    this.isLoadingProposal = true

    this.subscribeChannel({
      channel: 'financial-flow',
      events: [
        // -----------------------------------------------------
        // Fechamento/abertura da modal do fluxo financeiro
        {
          event: 'modal',
          fn: (modal) => {
            if (!modal) {
              this.FinancialFlowROIModal = false
              this.registration = null
            }
          }
        },
        {
          event: 'loading',
          fn: (loading) => {
            this.isLoadingProposal = loading
          }
        },
      ]
    })
  },

};