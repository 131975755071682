<template>
  <div style="">
    <h3 v-if="title !== null" style="">{{ title }}</h3>
    <v-skeleton-loader v-show="loading" type="image"></v-skeleton-loader>
    <canvas v-show="!loading" style="padding-bottom: 10px;" :id="canvaID"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

// Verifica se o plugin já foi registrado para evitar duplicidade
if (!Chart.plugins.getAll().some(p => p.id === 'roundedBarsPlugin')) {
  Chart.plugins.register({
    id: 'roundedBarsPlugin',
    afterDatasetsDraw: function (chart) {
      chart.data.datasets.forEach(function (dataset, datasetIndex) {
        if (dataset.type === "bar") { // Garantir que só arredondamos as barras
          const meta = chart.getDatasetMeta(datasetIndex);


          // // Acessa os valores de categoryPercentage e barPercentage
          // const categoryPercentage = chart.config.options.scales.xAxes[0].categoryPercentage || 0.8;
          // const barPercentage = chart.config.options.scales.xAxes[0].barPercentage || 0.6;

          meta.data.forEach(function (bar, index) {
            const ctx = chart.ctx;
            const model = bar._model;
            const yTop = model.y;
            const xLeft = model.x - model.width / 2;
            const xRight = model.x + model.width / 2;
            const yBottom = model.base;
            const radius = 10; // Valor para o arredondamento das barras

            // Se o valor for 0, não desenha a parte inferior arredondada
            const roundedCorners = yTop !== yBottom;

            ctx.clearRect(xLeft, yTop, xRight - xLeft, yBottom - yTop);

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(xLeft, yBottom);

            if (roundedCorners) {
              ctx.lineTo(xLeft, yTop + radius);
              ctx.quadraticCurveTo(xLeft, yTop, xLeft + radius, yTop);
              ctx.lineTo(xRight - radius, yTop);
              ctx.quadraticCurveTo(xRight, yTop, xRight, yTop + radius);
            } else {
              ctx.lineTo(xLeft, yTop);
              ctx.lineTo(xRight, yTop);
            }
            ctx.lineTo(xRight, yBottom);
            ctx.closePath();
            ctx.fillStyle = model.backgroundColor;
            ctx.fill();
            ctx.restore();
          });
        }
      });
    },
  });
}

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false, // Indica se o valor está carregando
    },
  },
  data() {
    return {
      id: null,
      canvaID: null,
      myChart: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
          },
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
            },
            offset: false,
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 100,
            },
            gridLines: {
              borderDash: [5, 5],
              display: true,
              color: "rgba(0, 0, 0, 0.2)",
            },
          }],
        },
      },
    };
  },
  created() {
    this.id = this._uid;
    this.canvaID = `graphic-consumption-${this.id}`;
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      if (this.myChart) {
        this.myChart.destroy();
      }

      const filteredDatasets = this.datasets.filter(dataset => {
        return dataset.data.some(value => value !== 0);
      });

      let ctx = document.getElementById(this.canvaID).getContext('2d');

      this.myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: filteredDatasets.map(dataset => ({
            ...dataset,
            backgroundColor: dataset.backgroundColor,
            borderSkipped: false,
            barThickness: dataset.type === "line" ? undefined : 20,
            type: dataset.type || "bar",
            order: dataset.type === "line" ? 2 : 1,  // Linhas desenhadas acima das barras
            fill: false,
            spanGaps: true,
            pointRadius: dataset.type === "line" ? 0 : undefined,
          })),
        },
        options: {
          ...this.options,
          scales: {
            ...this.options.scales,
            xAxes: [{
              ...this.options.scales.xAxes[0],
              offset: false, // Para expandir as linhas médias
            }]
          }
        },
      });
    },
    clearAndRedraw() {
      if (this.myChart) {
        this.myChart.destroy();
      }
      this.render();
    },
  },
  watch: {
    datasets: {
      immediate: true,
      handler(newValue) {
        if (newValue.length > 0) {
          this.$nextTick(() => {
            this.clearAndRedraw();
          });
        }
      },
    },
    labels: {
      immediate: true,
      handler(newValue) {
        if (newValue.length > 0) {
          this.$nextTick(() => {
            this.clearAndRedraw();
          });
        }
      },
    },
  },
};

</script>
