import firebase from "@/firebase";
import { mapActions, mapGetters, mapMutations } from "vuex";
import NewWaterFix from "../NewProposal/NewWaterFix.vue"
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper"
import EndPoints from "@/services/apiPdf/EndPoints";
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';
import SolutionsPoint from '@/services/firebase/SolutionsPoint/SolutionsPoint';
import WaterFixApi from "@/services/apiPdf/WaterFixApi";
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import { showInfoSnackBar, showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper";
import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';

import Signature from '@/components/Waterfix/Signature'

export default {
  name: "ConsultWaterFix",
  components: {
    NewWaterFix,
    Signature,
  },
  data: function () {
    return {
      itemsMenu: [
        { value: 'proposed', title: 'WaterFix', slug: 'waterfix' },
        { value: 'proposed', title: 'Termo de Ciência', slug: 'compromise-term' },
        { value: 'proposed', title: 'Termo de Instalação', slug: 'installation-term' },
        { value: 'proposed', title: 'Termo de Devolução', slug: 'return-term' },
        { value: 'proposed', title: 'Termo de Vistoria', slug: 'inspection-term' },
        { value: 'proposed', title: 'Ordem de Serviço', slug: 'order-service' },
      ],
      idProposalPDF: "",
      registration: null,
      openViewProposal: false,
      isLoadingProposal: false,
      registerProposalDelete: null,
      register: this.$route.params.register,
      proposalList: [],
      headers: [
        { text: "Matrícula", value: "registration" },
        { text: "Cliente", value: "client" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      searchItens: ["Matrícula", "Cliente"],
      // -------------------------------------------------------
      searchText: "",
      columnToSeach: "Cliente",
      proposalId: null,
      proposal: null,
      locationName: null,
      loading: false,
      choosePdfModal: false,
      termOption: 'water-fix',
      downloadOption: 'general',
      formGroupingOption: 'single',
      termOptions: [
        {
          name: 'Relatório Fotográfico',
          id: 'photo-report',
          request: WaterFixApi.downloadPhotoReport,
        },
        {
          name: 'Termo de Instalação',
          id: 'installation-term',
          request: WaterFixApi.downloadInstallationTerm,
        },
      ],
      terms_with_multiple_options: [
        'installation-term',
      ],
      options_by_terms: {
        'photo-report': ['Geral'],
        'installation-term': ['Geral', 'Local'],
      },
      chosen_option_by_term: {
        'photo-report': 'Geral',
        'installation-term': 'Geral',
      },
      chosen_local_by_term: {
        'photo-report': null,
        'installation-term': null,
      },
      locals: [],
      dialogSignature: false,
    }
  },
  methods: {
    ...mapActions([
      'setWaterFixOpenStatus',
      'subscribeChannel',
    ]),
    // ---------------------------------------------------------
    openChoosePdfModal() {
      this.choosePdfModal = true;
    },
    closeChoosePdfModal() {
      this.choosePdfModal = false;
      this.resetDownload()
    },
    setProposalId(proposalId) {
      this.proposalId = proposalId;
    },
    clearProposalId() {
      this.setProposalId(null)
    },
    setProposalRegistration(registration) {
      this.registration = registration;
    },
    clearProposalRegistration() {
      this.setProposalRegistration(null)
    },
    setLocationName(locationName) {
      this.locationName = locationName;
    },
    clearLocationName() {
      this.setLocationName(null)
    },
    setLoading(status) {
      this.loading = status
    },
    setLocals(proposal) {
      this.locals = proposal.solutionList.map(
        (item) => {
          return {
            id: Utils.createSlug(item.local),
            local: Utils.capitalizeString(item.local),
          }
        }
      )
    },
    newDownloadPdf(proposal) {
      const proposalId = proposal.id
      const registration = proposal.registration
      const locationName = proposal.locationInformation.name
      this.setLocals(proposal)
      this.setProposalId(proposalId)
      this.setProposalRegistration(registration)
      this.setLocationName(locationName)
      this.openChoosePdfModal()
    },
    async openDialogSignature() {
      this.setLoading(true)
      let requestParams = {
        proposalId: this.proposalId,
        term: this.termOption,
        download: 'general',
        group: 'single',
        locals: []
      }
      let request = this.termOptions.find((item) => item.id === this.termOption).request
      let documentPdfUrl = await request(this.locationName, requestParams, true)
      this.documentUrl = documentPdfUrl
      this.dialogSignature = true
      this.setLoading(false)
    },
    resetDownload() {
      this.termOption = this.termOptions[0].id;
      this.clearProposalId();
      this.clearProposalRegistration();
      this.clearLocationName();
    },
    async downloadPdfModal() {
      this.setLoading(true)
      let requestParams = {
        proposalId: this.proposalId,
        term: this.termOption,
        local: this.chosen_option_by_term[this.termOption] == 'Geral' ? null : this.chosen_local_by_term[this.termOption],
        signature: false,
      }
      let request = this.termOptions.find((item) => item.id === this.termOption).request

      try {
        await request(this.locationName, requestParams)
        showSuccessSnackBar("O download do PDF foi feito com sucesso!")
      } catch (e) {
        console.error(e)
        showErrorSnackBar("Ocorreu um erro interno na geração do PDF")
      }

      this.setLoading(false)
    },
    // ---------------------------------------------------------
    checkProposalPrices(proposal) {
      if (proposal) {
        if ('servicePrice' in proposal === true && 'solutionPrice' in proposal === true) {
          return false
        }
      }
      return true
    },
    async pdfRequest(proposal, action) {
      this.idProposalPDF = proposal.id
      let proposalPrices = proposal.proposed
      const solutionsData = await SolutionsServices.GetSolutions()
      const pointsData = await SolutionsPoint.getSolutionsPoint()

      proposalPrices.servicePrice.forEach((service, index) => {
        const solutionFinded = solutionsData.find(item => item.id === service.service)
        const solutionName = solutionFinded ? solutionFinded.solution : "- / -"
        proposalPrices.servicePrice[index].serviceNamePDF = solutionName
      })
      if (proposal.solutionList.length > 0) {
        proposal.solutionList.forEach((solution, index) => {
          const solutionFinded = solutionsData.find(item => item.id === solution.solution)
          const pointsFinded = pointsData.find(item => item.id === solution.type)
          const solutionName = solutionFinded ? solutionFinded.solution : "- / -"
          const pointName = pointsFinded ? pointsFinded.name : "- / -"
          proposal.solutionList[index].solutionNamePDF = solutionName
          proposal.solutionList[index].typeNamePDF = pointName
        });
      }

      proposal.consumptionHistoric.map(({ mounth }) => new Date(mounth.replace("-", "/")))
      proposal.consumptionHistoric.sort((a, b) => new Date(a.mounth.replace("-", "/")).getTime() - new Date(b.mounth.replace("-", "/")).getTime())

      let sendProposal = { ...proposal, proposed: proposalPrices }

      switch (action) {
        case 'proposed':
          this.statusRequest(await EndPoints.waterScanPdf(sendProposal))
          break;
        case 'checklist':
          this.statusRequest(await EndPoints.waterScanCheckListPdf(sendProposal))
          break;
      }
    },
    async statusRequest(res) {
      if (res) {
        this.idProposalPDF = ""
      } else (
        this.idProposalPDF = "",
        alert("Erro ao gerar o PDF!")
      )
    },
    openViewComponent(register) {
      this.registration = register
      this.openViewProposal = true;
    },
    closeComponent() {
      this.getCurrentProposal()
      this.openViewProposal = false;
      let path = '/water-fix/consult-proposal/all'
      if (this.$route.path !== path) this.$router.push(path);
    },
    getProposalDelet(register) {
      this.registerProposalDelete = register
      showDeleteDialogAlert(`Deseja excluir a proposta de matrícula "${register}"?`, this.deletProposal)
    },
    async deletProposal() {
      let register = this.registerProposalDelete
      this.isLoadingProposal = true
      await WaterFixService.deleteProposal(register)
      //Apenas para filtrar as propostas deletadas da visualização 
      this.proposalList = this.proposalList.filter((i, index) => {
        if (i.delete) {
          return
        }
        if (i.registration === register) {
          this.proposalList[index].delete = true
        }
        return i.registration !== register
      })
      this.isLoadingProposal = false
    },
    checkURL() {
      if (this.$route.params.register == "all") {
        this.getCurrentProposal()
      } else {
        this.openViewComponent(this.$route.params.register)
        this.getCurrentProposal()
      }
    },
    async getCurrentProposal() {
      this.isLoadingProposal = true
      await firebase.getProposalWaterFix()
        .then(response => {
          this.proposalList = response
          this.isLoadingProposal = false
        })
        .catch(err => {
          alert("Erro ao consultar propostas!")
          this.isLoadingProposal = false
          console.error(err)
        })
    },
  },
  computed: {
    filteredItems() {
      let text = Utils.defaultSpace(this.searchText)
      let list = this.proposalList
      list = list.filter((proposed) => {
        let value = ""
        if (this.columnToSeach == 'Matrícula') {
          value = proposed.registration
        } else if (this.columnToSeach == 'Cliente') {
          value = proposed.locationInformation.name
        }
        value = !value ? '' : value
        return (Utils.defaultSpace(value).toLowerCase().indexOf(text.toLowerCase()) > -1)
      });
      return list
    },
    rulesRequired() {
      return Rules.required();
    },
  },
  watch: {
    
  },
  async created() {
    this.checkURL();
  },
};